<template>
  <WebAppLayout>
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col cols="12" sm="6" md="5" lg="4">
          <div class="d-flex flex-column w-100">
            <div class="d-flex justify-center w-100">
              <img :src="iconColor" contain height="100" width="100" eager />
            </div>
            <div class="d-flex justify-center w-100">
              <v-progress-linear indeterminate color="primary" />
            </div>
            <div class="text-center w-100 py-6">
              <p class="m-font-heading font-weight-bold text-center">
                {{ appName }}
              </p>
              <p class="mt-2">
                <v-icon size="x-small" style="position: relative; top: -2px"
                  >mdi-shield-lock</v-icon
                >
                End-to-end encrypted
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </WebAppLayout>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import WebAppLayout from "../components/webAppLayout.vue";
export default defineComponent({
  name: "DefaultLayout",
  components: {
    WebAppLayout,
  },
  setup() {
    const malarkey = useUseMalarkeyConfig();
    const iconColor = computed(() => malarkey.visualFiles.iconColor);
    const appName = computed(() => malarkey.customizations.app.name);
    return {
      iconColor,
      appName,
    };
  },
});
</script>
